import React from "react";
import { Link } from "react-router-dom";

function Bredcrumb(props) {
  const { pageName } = props;
  return (
    <section id="inner-headline">
      <div className="container">
        <div className="row">
          <div className="span4">
            <div className="inner-heading">
              <h2>{pageName}</h2>
            </div>
          </div>
          <div className="span8">
            <ul className="breadcrumb">
              <li>
                <a href="#">
                  <i className="icon-home" />
                </a>
                <i className="icon-angle-right" />
              </li>
              <li>
                <a href="#">Home</a>
                <i className="icon-angle-right" />
              </li>
              <li className="active">{pageName}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bredcrumb;
