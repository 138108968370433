import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="span3">
            <div className="widget">
              <h5 className="widgetheading">Browse pages</h5>
              <ul className="link-list">
                <li>
                  <Link to="about">About our company</Link>
                </li>
                <li>
                  <Link to="services">Our services</Link>
                </li>
                <li>
                  <Link to="approach">Our Approach</Link>
                </li>
                <li>
                  <Link to="case-studies">Case Studies</Link>
                </li>
                <li>
                  <Link to="contact">Get in touch with us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="span3">
            <div className="widget">
              <h5 className="widgetheading">Resources</h5>
              <ul className="link-list">
                <li>
                  <a target="_blank" href="/assets/pdfs/terms-and-conditions.pdf">Terms and conditions</a>
                </li>
                <li>
                  <a target="_blank" href="/assets/pdfs/privacy-policy.pdf">Privacy policy</a>
                </li>
                <li>
                  <a href="https://mis.acetechindia.com" target="_blank">Employee center</a>
                </li>
                <li>
                  <a href="#">Acetech Blog</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="span3">
            <div className="widget"></div>
          </div>
          <div className="span3">
            <div className="widget">
              <h5 className="widgetheading">Get in touch with us</h5>
              <address>
                <strong>Acetech Information Systems Pvt. Ltd</strong>
                <br />
                <br />
                485, Vegas Mall, Sector 14 Dwarka, New Delhi, Delhi 110075
              </address>
              <p>
                <i className="icon-phone" /> +91-9650972345
                <br />
                <i className="icon-envelope-alt" /> info@acetechindia.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="sub-footer">
        <div className="container">
          <div className="row">
            <div className="span6">
              <div className="copyright">
                <p>
                  <span>© Acetech - All right reserved.</span>
                </p>
              </div>
            </div>
            <div className="span6">
              {/* <ul className="social-network">
                <li>
                  <a href="#" data-placement="bottom" title="Facebook">
                    <i className="icon-facebook icon-square" />
                  </a>
                </li>
                <li>
                  <a href="#" data-placement="bottom" title="Twitter">
                    <i className="icon-twitter icon-square" />
                  </a>
                </li>
                <li>
                  <a href="#" data-placement="bottom" title="Linkedin">
                    <i className="icon-linkedin icon-square" />
                  </a>
                </li>
                <li>
                  <a href="#" data-placement="bottom" title="Pinterest">
                    <i className="icon-pinterest icon-square" />
                  </a>
                </li>
                <li>
                  <a href="#" data-placement="bottom" title="Google plus">
                    <i className="icon-google-plus icon-square" />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </footer >
  );
}

export default Footer;
