import React from "react";
import ReactDOM from "react-dom";
//import './index.css';
import loadjs from "loadjs";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//import reportWebVitals from "./reportWebVitals";

//const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root"),
  loadjs("assets/js/custom.js")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
