import React from "react";
import Bredcrumb from "../common/header/Bredcrumb";

function CaseStudies(props) {
  return (
    <React.Fragment>
      <Bredcrumb pageName="Case Studies" />
      <section id="services" className="service">
        <div className="container">
          <div className="row">
            <h3 data-aos="fade-up" className="text-center pb-3"></h3>
            <p data-aos="fade-up" className="text-left pb-3"></p>
            <div className="accordion" id="accordion2">
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseOne"
                  >
                    Case Study 1 : Secure Cloud-based Document Management for US
                    Client
                  </a>
                  {/* <a
                    className=""
                    target="_blank"
                    href="assets/pdfs/Case-Study-DMS.pdf"
                  >
                    <i class="icon-rounded icon-cloud-download"></i>
                  </a> */}
                </div>
                <div id="collapseOne" className="accordion-body collapse in">
                  <div className="accordion-inner" style={{ height: "500px" }}>
                    <object
                      data="assets/pdfs/Case-Study-DMS.pdf"
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    ></object>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseTwo"
                  >
                    Case Study 2 : Transforming Workforce Deployment With NHS
                    ESR Integration
                  </a>

                  {/* <a
                    target="_blank"
                    href="assets/pdfs/Case-Study-e-Rostering-System-for-NHS-UK-Hospitals.pdf"
                  >
                    <i class="icon-rounded icon-cloud-download"></i>
                  </a> */}
                </div>
                <div id="collapseTwo" className="accordion-body collapse">
                  <div className="accordion-inner" style={{ height: "500px" }}>
                    <object
                      data="assets/pdfs/Case-Study-e-Rostering-System-for-NHS-UK-Hospitals.pdf"
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    ></object>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseThree"
                  >
                    Case Study 3 : Transforming Freight Forwarding Operations WithH Cloud-enabled Logistics Software
                  </a>

                  {/* <a
                    target="_blank"
                    href="assets/pdfs/Case-Study-e-Rostering-System-for-NHS-UK-Hospitals.pdf"
                  >
                    <i class="icon-rounded icon-cloud-download"></i>
                  </a> */}
                </div>
                <div id="collapseThree" className="accordion-body collapse">
                  <div className="accordion-inner" style={{ height: "500px" }}>
                    <object
                      data="assets/pdfs/case-study-seagate.pdf"
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    ></object>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseFour"
                  >
                    Case Study 4 : ER Tracker - Revolutionising Employee Relationship and Contract Management at NHS hospitals
                  </a>

                  {/* <a
                    target="_blank"
                    href="assets/pdfs/Case-Study-e-Rostering-System-for-NHS-UK-Hospitals.pdf"
                  >
                    <i class="icon-rounded icon-cloud-download"></i>
                  </a> */}
                </div>
                <div id="collapseFour" className="accordion-body collapse">
                  <div className="accordion-inner" style={{ height: "500px" }}>
                    <object
                      data="assets/pdfs/case-study-er-tracker.pdf"
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    ></object>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default CaseStudies;
