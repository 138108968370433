import React, { useEffect } from "react";

function Carousel(props) {
  return (
    <section id="featured">
      {/* start slider */}
      {/* Slider */}
      <div id="nivo-slider">
        <div className="nivo-slider">
          {/* Slide #1 image */}
          <img
            src="assets/img/slides/nivo/bigstock-hand-pushing-on-a-touch-screen-43291297-2800x1400.jpg"
            alt
            title="#caption-1"
          />
          {/* Slide #2 image */}
          <img
            src="assets/img/slides/nivo/aws-azure.jpg"
            alt
            title="#caption-2"
          />
          {/* Slide #3 image */}
          <img src="assets/img/slides/nivo/agile1.jpg" alt title="#caption-3" />
          {/* Slide #4 image */}
          <img
            src="assets/img/slides/nivo/success_stories.jpg"
            alt
            title="#caption-4"
          />
          {/* Slide #5 image */}
          <img
            src="assets/img/slides/nivo/mobile-applications.jpg"
            alt
            title="#caption-5"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="span12">
              {/* Slide #1 caption */}
              <div className="nivo-caption" id="caption-1">
                <div>
                  <h2>
                    Unlocking Possibilities in{" "}
                    <strong>Software Innovation</strong>
                  </h2>
                  <p>
                    We're your partners in crafting cutting-edge solutions that
                    drive business growth. From AWS and Azure expertise to agile
                    development and mobile apps, we're here to transform ideas
                    into digital realities.
                  </p>
                  <a href="#" className="btn btn-theme">
                    Read More
                  </a>
                </div>
              </div>
              {/* Slide #2 caption */}
              <div className="nivo-caption" id="caption-2">
                <div>
                  <h2>
                    Harnessing Azure and AWS for{" "}
                    <strong>Business Excellence</strong>
                  </h2>
                  <p>
                    Experience the power of cloud solutions. Our certified
                    experts leverage Microsoft Azure and Amazon Web Services to
                    build scalable, secure, and dynamic systems that empower
                    your enterprise's success.
                  </p>
                  <a href="#" className="btn btn-theme">
                    Read More
                  </a>
                </div>
              </div>
              {/* Slide #3 caption */}
              <div className="nivo-caption" id="caption-3">
                <div>
                  <h2>
                    Speed, Adaptability, Results:{" "}
                    <strong>Our Agile Approach</strong>
                  </h2>
                  <p>
                    Embrace the future with our Agile development philosophy. We
                    iterate, adapt, and innovate to deliver solutions that align
                    perfectly with your evolving needs, ensuring rapid results
                    and customer satisfaction.
                  </p>
                  <a href="#" className="btn btn-theme">
                    Read More
                  </a>
                </div>
              </div>
              {/* Slide #4 caption */}
              <div className="nivo-caption" id="caption-4">
                <div>
                  <h2>
                    Turning Challenges into Triumphs:{" "}
                    <strong>Our Success Stories</strong>
                  </h2>
                  <p>
                    Explore how we conquered complex projects, from the
                    intricate NHS Workforce Deployment System to the seamless
                    Document Management System. Discover how our expertise turns
                    challenges into triumphs.
                  </p>
                  <a href="#" className="btn btn-theme">
                    Read More
                  </a>
                </div>
              </div>
              {/* Slide #5 caption */}
              <div className="nivo-caption" id="caption-5">
                <div>
                  <h2>
                    From Vision to App:{" "}
                    <strong>Mobile Solutions by Acetech</strong>
                  </h2>
                  <p>
                    Mobile experiences that matter. Our React Native prowess
                    transforms ideas into stunning mobile apps that engage users
                    and elevate your brand. It's innovation in the palm of your
                    hand.
                  </p>
                  <a href="#" className="btn btn-theme">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end slider */}
    </section>
  );
}

export default Carousel;
