import React, { useEffect } from "react";

function Team(props) {
  return (
    <div className="row">
      <div className="span12">
        <h4>Our Team</h4>
      </div>
      <div className="span3">
        <img
          src="assets/img/team/anand-mohan2.jpeg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Anand Mohan</strong>
          </p>
          <p>CEO - Founder</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/manju-bisht.jpg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Manju Bisht</strong>
          </p>
          <p>Project Head</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/jitendra-sharma.jpg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Jitendra Sharma</strong>
          </p>
          <p>Sr. Delivery Manager</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/amir-khan.png"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Amir Khan</strong>
          </p>
          <p>Tech Lead</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/navin-kumar.jpeg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Naveen Kumar</strong>
          </p>
          <p>Sr. Developer</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/ramkrit-r.jpeg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Ramkrit</strong>
          </p>
          <p>Sr. Developer</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/anshu-rao.png"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Anshu Rao</strong>
          </p>
          <p>React Developer</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/neeraj-rawat.jpg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Neeraj Rawat</strong>
          </p>
          <p>QA Analyst</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/karan-pandey.jpg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Karan Pandey</strong>
          </p>
          <p>QA Analyst</p>
        </div>

      </div>
      <div className="span3">
        <img
          src="assets/img/team/leela-gusai.jpg"
          alt
          className="img-polaroid"
        />
        <div className="roles">
          <p className="lead">
            <strong>Leela Gusai</strong>
          </p>
          <p>QA Analyst</p>
        </div>

      </div>
    </div>
  );
}

export default Team;
