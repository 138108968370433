import React from "react";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <header>
      <div className="container ">
        {/* end toggle link */}
        <div className="row nomargin">
          <div className="span12">
            <div className="headnav">
              <ul>
                <li>
                  <a
                    href="https://mis.acetechindia.com"
                    target="_blank"
                    data-toggle="modal"
                  >
                    Employee Sign in
                  </a>
                </li>
              </ul>
            </div>
            {/* Signup Modal */}
            <div
              id="mySignup"
              className="modal styled hide fade"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="mySignupModalLabel"
              aria-hidden="true"
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 id="mySignupModalLabel">
                  Create an <strong>account</strong>
                </h4>
              </div>
              <div className="modal-body">
                <form className="form-horizontal">
                  <div className="control-group">
                    <label className="control-label" htmlFor="inputEmail">
                      Email
                    </label>
                    <div className="controls">
                      <input type="text" id="inputEmail" placeholder="Email" />
                    </div>
                  </div>
                  <div className="control-group">
                    <label
                      className="control-label"
                      htmlFor="inputSignupPassword"
                    >
                      Password
                    </label>
                    <div className="controls">
                      <input
                        type="password"
                        id="inputSignupPassword"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <label
                      className="control-label"
                      htmlFor="inputSignupPassword2"
                    >
                      Confirm Password
                    </label>
                    <div className="controls">
                      <input
                        type="password"
                        id="inputSignupPassword2"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="controls">
                      <button type="submit" className="btn">
                        Sign up
                      </button>
                    </div>
                    <p className="aligncenter margintop20">
                      Already have an account?{" "}
                      <a
                        href="#mySignin"
                        data-dismiss="modal"
                        aria-hidden="true"
                        data-toggle="modal"
                      >
                        Sign in
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            {/* end signup modal */}
            {/* Sign in Modal */}
            <div
              id="mySignin"
              className="modal styled hide fade"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="mySigninModalLabel"
              aria-hidden="true"
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 id="mySigninModalLabel">
                  Login to your <strong>account</strong>
                </h4>
              </div>
              <div className="modal-body">
                <form className="form-horizontal">
                  <div className="control-group">
                    <label className="control-label" htmlFor="inputText">
                      Username
                    </label>
                    <div className="controls">
                      <input
                        type="text"
                        id="inputText"
                        placeholder="Username"
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <label
                      className="control-label"
                      htmlFor="inputSigninPassword"
                    >
                      Password
                    </label>
                    <div className="controls">
                      <input
                        type="password"
                        id="inputSigninPassword"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="controls">
                      <button type="submit" className="btn">
                        Sign in
                      </button>
                    </div>
                    <p className="aligncenter margintop20">
                      Forgot password?{" "}
                      <a
                        href="#myReset"
                        data-dismiss="modal"
                        aria-hidden="true"
                        data-toggle="modal"
                      >
                        Reset
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            {/* end signin modal */}
            {/* Reset Modal */}
            <div
              id="myReset"
              className="modal styled hide fade"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myResetModalLabel"
              aria-hidden="true"
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 id="myResetModalLabel">
                  Reset your <strong>password</strong>
                </h4>
              </div>
              <div className="modal-body">
                <form className="form-horizontal">
                  <div className="control-group">
                    <label className="control-label" htmlFor="inputResetEmail">
                      Email
                    </label>
                    <div className="controls">
                      <input
                        type="text"
                        id="inputResetEmail"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="controls">
                      <button type="submit" className="btn">
                        Reset password
                      </button>
                    </div>
                    <p className="aligncenter margintop20">
                      We will send instructions on how to reset your password to
                      your inbox
                    </p>
                  </div>
                </form>
              </div>
            </div>
            {/* end reset modal */}
          </div>
        </div>
        <div className="row">
          <div className="span4">
            <div className="logo">
              <a href="/">
                <img src="assets/img/logo.png" alt className="logo" />
              </a>
            </div>
            <div className="logo">
              Empowering Innovation Through Software Excellence
            </div>
          </div>
          <div className="span8">
            <div className="navbar navbar-static-top">
              <div className="navigation">
                <nav>
                  <ul className="nav topnav">
                    <li className="dropdown active">
                      <Link to="/">Home </Link>
                    </li>
                    <li className="dropdown">
                      <Link to="about">About Us</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="services">Our Services</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="approach">Our Approach</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="case-studies">Case Studies</Link>
                    </li>
                    <li>
                      <Link to="contact">Contact </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* end navigation */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
