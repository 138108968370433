import React from "react";
import Bredcrumb from "../common/header/Bredcrumb";

function Contact(props) {
  return (
    <React.Fragment>
      <Bredcrumb pageName="Contact Us" />
      <section id="content">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d56047.14066861177!2d77.030259!3d28.601388!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b3d95555591%3A0x43c9fe791aed7a0e!2sAcetech%20Information%20Systems%20Pvt.%20Ltd!5e0!3m2!1sen!2sus!4v1692706629050!5m2!1sen!2sus"
          width="100%"
          height={380}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
        />
        <div className="container">
          <div className="row">
            <div className="span12">
              <h4>
                Get in touch with us by filling{" "}
                <strong>contact form below</strong>
              </h4>
              <form action method="post" role="form" className="contactForm">
                <div id="sendmessage">
                  Your message has been sent. Thank you!
                </div>
                <div id="errormessage" />
                <div className="row">
                  <div className="span4 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validation" />
                  </div>
                  <div className="span4 form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                    />
                    <div className="validation" />
                  </div>
                  <div className="span4 form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 8 chars of subject"
                    />
                    <div className="validation" />
                  </div>
                  <div className="span12 margintop10 form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={12}
                      data-rule="required"
                      data-msg="Please write something for us"
                      placeholder="Message"
                      defaultValue={""}
                    />
                    <div className="validation" />
                    <p className="text-center">
                      <button
                        className="btn btn-large btn-theme margintop10"
                        type="submit"
                      >
                        Submit message
                      </button>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Contact;
