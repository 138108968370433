import React, { useEffect } from "react";

function Clients(props) {
  return (
    <section id="content">
      <div className="container">
        <div className="row">
          <div className="span12">
            <h4>
              Trusted <strong>Partnerships</strong>
            </h4>

            <ul id="mycarousel" className="clients">
              <li>
                <a href="#">
                  <img
                    src="assets/img/clients/nokia_white_logo.png"
                    className="client-logo"
                    alt
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="assets/img/clients/premier-it-logo.webp"
                    className="client-logo"
                    alt
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="assets/img/clients/IPCS-logo.png"
                    className="client-logo"
                    alt
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="assets/img/clients/zebra_logo.png"
                    className="client-logo"
                    alt
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="assets/img/clients/seagate.jpg"
                    className="client-logo"
                    alt
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="assets/img/clients/dps-logo.jpg"
                    className="client-logo"
                    alt
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="assets/img/clients/unesta-logo.jpg"
                    className="client-logo"
                    alt
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
