import React, { useEffect } from "react";
import Bredcrumb from "../common/header/Bredcrumb";

function Services(props) {
  useEffect(() => {
    // Create meta description element
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Discover our comprehensive range of services, including Azure & AWS cloud solutions, Agile Development, and React Native mobile app development. We\'re your partners in software innovation and transformation.';

    // Create meta keywords element
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'Azure & AWS solutions, Agile Development, React Native mobile app development, software innovation, software solutions, transformation';

    // Append meta elements to the head
    document.head.appendChild(metaDescription);
    document.head.appendChild(metaKeywords);

    // Clean up the appended elements when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <React.Fragment>
      <Bredcrumb pageName="Our Services" />
      <main id="main">

        <section id="services" className="service">
          <div className="container">
            <div className="row">
              <h3 data-aos="fade-up" className="text-center pb-3">

              </h3>
              <p data-aos="fade-up" className="text-left pb-3">

              </p>
              <div className="accordion" id="accordion2">
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseOne"
                    >
                      1. Custom Software Development
                    </a>
                  </div>
                  <div id="collapseOne" className="accordion-body collapse in">
                    <div className="accordion-inner">
                      <p>Custom Software Solutions Tailored to Your Business Needs
                      </p>
                      <p>
                        At Acetech Information Systems Pvt. Ltd., we understand that every business is unique, and off-the-shelf software solutions might not address your specific challenges. Our custom software development services are designed to create bespoke solutions that align perfectly with your business processes, boosting efficiency, productivity, and innovation. We collaborate closely with your team to gather requirements, design intuitive user interfaces, and develop robust backend systems that streamline your operations. Whether you need a standalone application or an integrated suite of tools, our experts are here to transform your vision into reality.
                      </p>
                      <p>Learn More About Custom Software Development.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseTwo"
                    >
                      2. Database Development
                    </a>
                  </div>
                  <div id="collapseTwo" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Seamless Data Management for Informed Decision-Making</p>
                      <p>
                        Effective data management is at the core of successful businesses. Our database development services at Acetech Information Systems Pvt. Ltd. are dedicated to designing and building databases that seamlessly handle your data, ensuring accuracy, security, and accessibility. Our experienced team specializes in creating database solutions that enable you to organize, store, and retrieve information efficiently. From data modeling and schema design to optimizing queries and implementing data security measures, we provide end-to-end database solutions that empower you to make informed decisions based on reliable data.
                      </p>
                      <p>Explore the Power of Effective Database Solutions.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseThree"
                    >
                      3. Cloud Solutions: AWS and Azure
                    </a>
                  </div>
                  <div id="collapseThree" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Embrace the Power of Cloud for Scalability and Flexibility</p>
                      <p>
                        In today's dynamic business landscape, the cloud is a game-changer. At Acetech Information Systems Pvt. Ltd., we offer cloud solutions on both AWS and Azure platforms, enabling your business to scale, innovate, and adapt with agility. Our experts leverage the capabilities of these leading cloud providers to design, deploy, and manage cloud-based applications and services that match your unique requirements. From infrastructure design to migration strategies, we ensure that your cloud environment is secure, optimized, and aligned with your business goals.
                      </p>
                      <p>Discover the Future of Scalable Cloud Solutions.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseFour"
                    >
                      4. React Native Mobile App Development
                    </a>
                  </div>
                  <div id="collapseFour" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Engage Your Audience with High-Performance Mobile Apps
                      </p>
                      <p>
                        Mobile applications are the cornerstone of modern customer engagement. At Acetech Information Systems Pvt. Ltd., our React Native mobile app development services combine cutting-edge technology with user-centric design to create mobile apps that resonate with your audience. We specialize in building apps that deliver a seamless user experience across both iOS and Android platforms, using a single codebase. From concept to deployment, our experts craft intuitive and visually appealing apps that drive customer satisfaction and business growth.
                      </p>
                      <p>Elevate Your Business with Next-Gen Mobile Apps.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseFive"
                    >
                      5. Microsoft Azure and AWS Expertise
                    </a>
                  </div>
                  <div id="collapseFive" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Harness the Full Potential of Cloud with Automated Deployment</p>
                      <p>
                        As cloud technology continues to evolve, automated deployment has become a crucial aspect of efficient software development. At Acetech Information Systems Pvt. Ltd., our Microsoft Azure and AWS expertise goes beyond basic cloud services. We specialize in implementing Azure Release Pipeline-based automatic deployment, allowing you to streamline your development and deployment processes. With our guidance, you can automate testing, build, and deployment tasks, reducing manual intervention and accelerating your time-to-market.
                      </p>
                      <p>Leverage the Power of Automated Deployment on Azure and AWS.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseSix"
                    >
                      6.  ASP.Net Development
                    </a>
                  </div>
                  <div id="collapseSix" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Creating Robust Web Applications with ASP.Net
                      </p>
                      <p>
                        When it comes to building powerful and dynamic web applications, Acetech Information Systems Pvt. Ltd. stands at the forefront of ASP.Net development. Our team of experienced developers excels in utilizing the capabilities of ASP.Net to craft web applications that deliver exceptional user experiences. We blend creativity and technical expertise to develop interactive and responsive web solutions that align with your business objectives. Whether you need an e-commerce platform, a content management system, or a custom web application, we have the expertise to bring your vision to life.
                      </p>
                      <p>Explore the Possibilities of ASP.Net Development.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseSeven"
                    >
                      7.  Desktop Applications using .NET Core
                    </a>
                  </div>
                  <div id="collapseSeven" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Next-Gen Desktop Applications for Enhanced Performance</p>
                      <p>
                        Desktop applications remain a vital part of various business operations, and with .NET Core, they've reached a new level of performance and versatility. Acetech Information Systems Pvt. Ltd. specializes in creating desktop applications using .NET Core, a cross-platform framework that ensures consistent functionality across Windows, macOS, and Linux. Our skilled developers leverage the latest features of .NET Core to build intuitive and efficient desktop applications that cater to your specific requirements. From design to implementation, we are dedicated to delivering desktop solutions that drive productivity and user satisfaction.
                      </p>
                      <p>Experience the Future of Desktop Applications with .NET Core.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseEight"
                    >
                      8.  Graph Databases (Neo4j)
                    </a>
                  </div>
                  <div id="collapseEight" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Uncover Insights from Complex Data Relationships
                      </p>
                      <p>
                        Uncover Insights from Complex Data Relationships
                        In a data-driven world, understanding complex relationships within your data is key to gaining valuable insights. Acetech Information Systems Pvt. Ltd. offers expertise in graph databases, specifically Neo4j, a leading graph database technology. Our graph database solutions allow you to model, store, and analyze data relationships more effectively, revealing hidden patterns and connections. Whether you're working with social networks, recommendation engines, or any application involving intricate data connections, our skilled team can assist you in harnessing the power of graph databases for better decision-making.
                      </p>
                      <p>Dive into the World of Graph Databases with Neo4j</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseNine"
                    >
                      9.  Microsoft Power BI Analytics
                    </a>
                  </div>
                  <div id="collapseNine" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Transform Data into Actionable Insights
                      </p>
                      <p>
                        Data holds the potential to drive informed decision-making, but it needs to be transformed into meaningful insights. Acetech Information Systems Pvt. Ltd. specializes in Microsoft Power BI Analytics, a powerful tool that enables businesses to create interactive and visually compelling dashboards from raw data. Our experts combine data visualization techniques with business intelligence to craft insightful reports and dashboards that allow you to understand trends, identify opportunities, and make data-driven decisions with confidence.
                      </p>
                      <p>Empower Your Business with Microsoft Power BI Analytics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseTen"
                    >
                      10.  Salesforce Development
                    </a>
                  </div>
                  <div id="collapseTen" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Optimize Sales and Customer Management with Customized Solutions
                      </p>
                      <p>
                        Efficient sales and customer management are vital for business success. Acetech Information Systems Pvt. Ltd. offers Salesforce development services tailored to streamline your sales processes, enhance customer engagement, and drive growth. Our experienced developers customize Salesforce solutions to match your unique requirements, integrating modules, automating workflows, and creating a seamless user experience. Whether you need to manage leads, track opportunities, or provide exceptional customer service, our Salesforce solutions empower you to optimize your business operations.
                        <p>Unlock the Potential of Salesforce Development.</p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseEleven"
                    >
                      11.  Codeigniter
                    </a>
                  </div>
                  <div id="collapseEleven" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>Creating Dynamic Web Applications with Codeigniter.</p>
                      <p>
                        In the world of web development, Codeigniter stands out as a PHP framework that allows for rapid development of dynamic and interactive web applications. Acetech Information Systems Pvt. Ltd. specializes in harnessing the capabilities of Codeigniter to create web solutions that engage users and drive conversions. Our developers leverage the flexibility and simplicity of Codeigniter to build feature-rich web applications that cater to your business needs. From e-commerce platforms to content management systems, our Codeigniter expertise ensures that your web applications are functional, user-friendly, and tailored to your goals.
                      </p>
                      <p>Experience the Power of Codeigniter in Web Development.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}

export default Services;
