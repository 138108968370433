import React, { useEffect } from "react";
import Services from "./Services";
import Cta from "./Cta";
import Portfolio from "./Portfolio";
import Clients from "./Clients";
import Carousel from "../common/header/Carousel";
import { Link } from "react-router-dom";

function Home(props) {
  useEffect(() => {
    // Create meta description element
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Welcome to Acetech Information Systems Pvt. Ltd. With a decade of experience, we\'ve been at the forefront of software innovation, delivering cutting-edge solutions to businesses of all sizes. Explore our expertise in Azure & AWS cloud solutions, Agile Development, and React Native mobile app development.';

    // Create meta keywords element
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'software innovation, Acetech Information Systems, Azure & AWS solutions, Agile Development, React Native mobile app development';

    // Append meta elements to the head
    document.head.appendChild(metaDescription);
    document.head.appendChild(metaKeywords);

    const script = document.createElement("script");
    script.src = "assets/js/custom.js"; //(This is external js url)
    script.async = true;
    document.body.appendChild(script);

    // Clean up the appended elements when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };


  }, []);
  return (
    <React.Fragment>
      <Carousel />
      <Cta />
      <section id="content">
        <div className="container">
          <div className="row">
            <div className="span12">
              <div className="row">
                <div className="span3">
                  <div className="box aligncenter">
                    <div className="aligncenter icon">
                      <i className="icon-briefcase icon-circled icon-64 active" />
                    </div>
                    <div className="text">
                      <h6>Custom Software Development</h6>
                      <p>
                        Tailored software solutions that align with your
                        business processes, boosting efficiency and innovation.
                      </p>
                      <Link to="services">Learn more</Link>
                    </div>
                  </div>
                </div>
                <div className="span3">
                  <div className="box aligncenter">
                    <div className="aligncenter icon">
                      <i className="icon-desktop icon-circled icon-64 active" />
                    </div>
                    <div className="text">
                      <h6>Cloud Solutions: AWS and Azure</h6>
                      <p>
                        Leverage the power of the cloud with our AWS and Azure
                        solutions, providing scalability, security, and
                        flexibility.
                      </p>
                      <Link to="services">Learn more</Link>
                    </div>
                  </div>
                </div>
                <div className="span3">
                  <div className="box aligncenter">
                    <div className="aligncenter icon">
                      <i className="icon-beaker icon-circled icon-64 active" />
                    </div>
                    <div className="text">
                      <h6>React Native Mobile App Development</h6>
                      <p>
                        Reach your audience on the go with intuitive and
                        high-performing mobile applications.
                      </p>
                      <Link to="services">Learn more</Link>
                    </div>
                  </div>
                </div>
                <div className="span3">
                  <div className="box aligncenter">
                    <div className="aligncenter icon">
                      <i className="icon-coffee icon-circled icon-64 active" />
                    </div>
                    <div className="text">
                      <h6>ASP.Net Development</h6>
                      <p>
                        Building robust web applications using ASP.Net for a
                        responsive and dynamic user experience.
                      </p>
                      <Link to="services">Learn more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* divider */}
          <div className="row">
            <div className="span12">
              <div className="solidline"></div>
            </div>
          </div>
          {/* end divider */}
          <div className="row">
            <div className="span12">
              <div className="row">
                <div className="span3">
                  <div className="box aligncenter">
                    <div className="aligncenter icon">
                      <i className="icon-briefcase icon-circled icon-64 active" />
                    </div>
                    <div className="text">
                      <h6>Graph Databases (Neo4J):</h6>
                      <p>
                        Uncover valuable insights from complex data
                        relationships with our graph database expertise.
                      </p>
                      <Link to="services">Learn more</Link>
                    </div>
                  </div>
                </div>
                <div className="span3">
                  <div className="box aligncenter">
                    <div className="aligncenter icon">
                      <i className="icon-desktop icon-circled icon-64 active" />
                    </div>
                    <div className="text">
                      <h6>Microsoft Power BI Analytics</h6>
                      <p>
                        Transform data into actionable insights with interactive
                        and visually compelling dashboards.
                      </p>
                      <Link to="services">Learn more</Link>
                    </div>
                  </div>
                </div>
                <div className="span3">
                  <div className="box aligncenter">
                    <div className="aligncenter icon">
                      <i className="icon-coffee icon-circled icon-64 active" />
                    </div>
                    <div className="text">
                      <h6>.NET Core Development:</h6>
                      <p>
                        Crafting powerful desktop applications with .NET Core
                        for enhanced performance and usability.
                      </p>
                      <Link to="services">Learn more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Clients />
    </React.Fragment>
  );
}

export default Home;
