import React, { useEffect } from "react";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import Topbar from "./components/common/header/Topbar";
import { Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Layout from "./components/common/layout/Layout";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Services from "./components/pages/Services";
import Approach from "./components/pages/Approach";
import CaseStudies from "./components/pages/CaseStudies";

function App() {
  return (
    <React.Fragment>
      <Topbar />
      <Header />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="services" element={<Services />} />
          <Route path="approach" element={<Approach />} />
          <Route path="case-studies" element={<CaseStudies />} />
        </Route>
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
