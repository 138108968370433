import React from "react";

function Cta(props) {
  return (
    <section className="callaction">
      <div className="container">
        <div className="row">
          <div className="span12">
            <div className="big-cta">
              <div className="cta-text">
                <h3>
                  Ready to{" "}
                  <span className="highlight">
                    <strong>Innovate</strong>
                  </span>{" "}
                  ? Let's Transform Your Vision into Reality!
                </h3>
              </div>
              <div className="cta floatright">
                <a className="btn btn-large btn-theme btn-rounded" href="contact">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
