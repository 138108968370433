import React, { useEffect } from "react";
import Bredcrumb from "../common/header/Bredcrumb";
import Team from "./Team";
import Skills from "./Skills";
import Clients from "./Clients";

function About(props) {
  useEffect(() => {
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = "With a decade of experience, Acetech delivers cutting-edge software solutions to businesses of all sizes. Specializing in Azure, AWS, Agile Development, and React Native mobile apps.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = "software innovation, Azure, AWS, Agile Development, React Native mobile apps";
    document.head.appendChild(metaKeywords);

    const script = document.createElement("script");
    script.src = "assets/js/custom.js"; //(This is external js url)
    script.async = true;
    document.body.appendChild(script);


    // Clean up the appended elements when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };

  }, []);
  return (
    <React.Fragment>
      <Bredcrumb pageName="About Us" />
      <section id="content">
        <div className="container">
          <div className="row">
            <div className="span6">
              <h2>
                Unlocking Possibilities in<strong> Software Innovation</strong>
              </h2>
              <p>
                Welcome to Acetech Information Systems Pvt. Ltd. With a decade
                of experience, we've been at the forefront of software
                innovation, delivering cutting-edge solutions to businesses of
                all sizes.
              </p>
              <p>
                <strong>Our Expertise</strong>: Our core expertise lies in harnessing the power
                of Microsoft Azure and Amazon Web Services (AWS) to craft
                scalable, secure, and dynamic systems. We're not just
                developers; we're problem solvers, solution architects, and your
                partners in progress.
              </p>
              <p>
                <strong>Service Highlights</strong>: Azure &amp; AWS Solutions: Explore our
                expertise in Azure and AWS cloud solutions that empower
                businesses with flexibility, efficiency, and enhanced
                capabilities. Whether it's cloud migration, architecture design,
                or optimization, we've got you covered.
              </p>
              <p>
                <strong>Agile Development</strong>: Agility is in our DNA. Our Agile approach
                ensures that your projects evolve with your needs. We adapt,
                iterate, and innovate to deliver results faster while
                maintaining quality and collaboration.
              </p>
              <p>
                <strong>Mobile Apps</strong>: From concept to app store, we specialize in React
                Native mobile app development. Your ideas come to life as
                engaging, user-friendly applications that deliver seamless
                experiences on any device.
              </p>
              <p>
                <strong>Ready to innovate with us?</strong>
              </p>
              <p>Learn more about our
                services and how we can partner to achieve your business goals.
                Contact us today to start your transformative journey.
              </p>
            </div>
            <div className="span6">
              {/* start flexslider */}
              <div className="flexslider">
                <ul className="slides">
                  <li>
                    <img src="assets/img/works/full/about-image-1.jpg" alt />
                  </li>
                  <li>
                    <img src="assets/img/works/full/about-image-2.jpg" alt />
                  </li>
                  <li>
                    <img src="assets/img/works/full/about-image-3.jpg" alt />
                  </li>
                </ul>
              </div>
              {/* end flexslider */}
            </div>
          </div>
          {/* divider */}
          <div className="row">
            <div className="span12">
              <div className="solidline"></div>
            </div>
          </div>
          {/* end divider */}

          <Team />
          {/* divider */}
          <div className="row">
            <div className="span12">
              <div className="solidline"></div>
            </div>
          </div>
          {/* end divider */}
          <div className="row">
            <div className="span6">
              <h4>More about us</h4>
              <div className="accordion" id="accordion2">
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseOne"
                    >
                      1. What we do{" "}
                    </a>
                  </div>
                  <div id="collapseOne" className="accordion-body collapse in">
                    <div className="accordion-inner">
                      <p>
                        At Acetech Information Systems, we breathe life into
                        digital dreams. From crafting software solutions that
                        empower businesses on Azure and AWS to pioneering mobile
                        apps, we're your dedicated partners in transforming
                        ideas into innovation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseTwo"
                    >
                      2. Work process{" "}
                    </a>
                  </div>
                  <div id="collapseTwo" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>
                        At Acetech Information Systems, we breathe life into
                        digital dreams. From crafting software solutions that
                        empower businesses on Azure and AWS to pioneering mobile
                        apps, we're your dedicated partners in transforming
                        ideas into innovation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseThree"
                    >
                      3. Quality assurance{" "}
                    </a>
                  </div>
                  <div id="collapseThree" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>
                        Quality is not just a promise; it's a commitment we live
                        by. Our dedicated quality assurance team rigorously
                        tests every aspect of our solutions to ensure they're
                        robust, reliable, and ready to make a real impact in the
                        hands of users.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-group">
                  <div className="accordion-heading">
                    <a
                      className="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseFour"
                    >
                      4. What we can deliver{" "}
                    </a>
                  </div>
                  <div id="collapseFour" className="accordion-body collapse">
                    <div className="accordion-inner">
                      <p>
                        We deliver more than code; we deliver experiences. From
                        cloud-powered solutions to seamless mobile apps, our
                        portfolio is a testament to our ability to turn complex
                        challenges into tangible outcomes that drive growth and
                        elevate user engagement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="span6">
              <h4>Our expertise</h4>
              <label>Azure &amp; AWS:</label>
              <div className="progress progress-info progress-striped active">
                <div className="bar" style={{ width: "90%" }}></div>
              </div>
              <label>Agile Development Prowess :</label>
              <div className="progress progress-success progress-striped active">
                <div className="bar" style={{ width: "95%" }}></div>
              </div>
              <label>Mobile App Innovators :</label>
              <div className="progress progress-warning progress-striped active">
                <div className="bar" style={{ width: "85%" }}></div>
              </div>
              <label>Advanced Database Management :</label>
              <div className="progress progress-danger progress-striped active">
                <div className="bar" style={{ width: "88%" }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Clients />
    </React.Fragment>
  );
}

export default About;
