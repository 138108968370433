import React from "react";
import Bredcrumb from "../common/header/Bredcrumb";

function Approach(props) {
  return (
    <React.Fragment>
      <Bredcrumb pageName="Our Approach" />
      <section id="about-us" className="about-us">
        <div className="container">
          <div className="row">
            <h3 data-aos="fade-up" className="text-center pb-3">
              Experience Our Approach
            </h3>
            <p data-aos="fade-up" className="text-left pb-3">
              At Acetech Information Systems, our approach isn't just a
              methodology; it's a promise of excellence. We invite you to
              experience our collaborative process, Agile development
              philosophy, and unwavering commitment to quality firsthand.
              Join us on a journey where innovation meets collaboration, and
              where results are not just achieved, but exceeded.
            </p>
            <div className="accordion" id="accordion2">
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseOne"
                  >
                    1. Ensuring Collaborative Excellence and Agile Development
                  </a>
                </div>
                <div id="collapseOne" className="accordion-body collapse in">
                  <div className="accordion-inner">
                    <p>
                      Our methodology reflects a commitment to collaboration, transparency, and delivering quality solutions that exceed client expectations. Discover how we ensure a seamless journey from inception to execution.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseTwo"
                  >
                    2. Collaborative Process: Fostering Open Communication
                  </a>
                </div>
                <div id="collapseTwo" className="accordion-body collapse">
                  <div className="accordion-inner">
                    <p>
                      At Acetech Information Systems Pvt. Ltd., collaboration lies at the heart of our approach. We believe that a successful project thrives on constant communication between our team and our clients. This collaborative process is facilitated through cutting-edge tools like Jira, BitBucket, and Confluence. These platforms not only provide visibility into project progress but also enable real-time feedback and engagement. We work hand in hand with our clients, ensuring alignment of objectives, managing expectations, and addressing potential roadblocks proactively.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseThree"
                  >
                    3. Agile Development: Navigating Change with Flexibility
                  </a>
                </div>
                <div id="collapseThree" className="accordion-body collapse">
                  <div className="accordion-inner">
                    <p>
                      Our commitment to Agile development methodology sets the tone for our projects. Agile allows us to embrace change, respond to evolving requirements, and deliver incremental value to our clients. By breaking down projects into manageable sprints, we ensure constant progress and allow room for adjustments based on feedback. In environments like Azure DevOps and Git Repos, Agile principles come to life as we maintain close collaboration and adapt to changing needs swiftly. This methodology guarantees that the final solution aligns precisely with the client's vision and business goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseFour"
                  >
                    4. Quality Assurance: Elevating Excellence at Every Stage
                  </a>
                </div>
                <div id="collapseFour" className="accordion-body collapse">
                  <div className="accordion-inner">
                    <p>
                      Quality isn't just a goal; it's ingrained in our DNA. Our Quality Assurance (QA) process ensures that every piece of code is rigorously tested, scrutinized, and refined to meet the highest standards. With tools like Sonar for code quality assessment and Lint tools for code analysis, we leave no stone unturned in identifying and rectifying potential issues. Azure DevOps Test Plans facilitate comprehensive testing, ensuring that the final product is not only functional but also reliable and robust.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseFive"
                  >
                    5. Agile Development with QA Integration: Seamless Progress,
                    Stellar Outcomes
                  </a>
                </div>
                <div id="collapseFive" className="accordion-body collapse">
                  <div className="accordion-inner">
                    <p>
                      Combining Agile development with our QA focus creates a dynamic synergy. We maintain transparency through Azure DevOps CI/CD pipelines, which facilitate continuous integration and deployment, ensuring that every code change is thoroughly tested and validated before being released. Azure DevOps Test Plans further contribute by providing a comprehensive framework for testing scenarios, ensuring that every feature works flawlessly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-group">
                <div className="accordion-heading">
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent="#accordion2"
                    href="#collapseSix"
                  >
                    6.  Continuous Improvement: Iterating Towards Excellence
                  </a>
                </div>
                <div id="collapseSix" className="accordion-body collapse">
                  <div className="accordion-inner">
                    <p>
                      Our approach is not static; it's an ever-evolving process driven by a commitment to continuous improvement. We leverage the insights gained from collaboration tools, code quality assessments, and testing to refine our processes and enhance our service delivery. Regular retrospectives help us identify areas for enhancement, empowering us to deliver results that constantly exceed expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section id="testimonials" className="testimonials section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12" data-aos="fade-up">
              <div className="testimonial-item text-center">
                Contact us today to embark on a partnership that transcends
                conventional development and delivers exceptional solutions
                tailored to your unique needs.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Approach;
